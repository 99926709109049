/* Camera.css */

.camera-container {
    position: relative;
    width: 100%;
    max-width: 640px;
    width: 500px;
    height: 339px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1px;
    border: 1px solid #e0e0e0;
  }
  
  .camera-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  
  /* .camera-container .template {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 640px;
    height: 480px;
    border: 2px solid #ddd; /* 
    border-radius: 50%/60%;
    background: rgba(0, 0, 0, 0.3); 
    transition: all 0.3s ease; 
} */

.camera-container .template::before,
.camera-container .template::after {
    content: "";
    display: block;
    position: absolute;
    top: 26%;
    width:45px;
    height: 42px;
    background: rgba(0, 0, 0, 0.3); /* reduce la opacidad del fondo para un aspecto más suave */
    border: 2px solid #ddd; /* cambia el color del borde a un gris suave */
    border-radius: 50%;
    transition: all 0.3s ease; /* agrega una transición suave a todos los cambios de propiedad */
}

.camera-container .template::before {
    left: 18%;
}

.camera-container .template::after {
    right: 18%;
}

  
  .camera-container canvas {
    display: none;
  }
  
  .camera-container button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #0087F7;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  /* .item-row {
    display: flex;
    align-items: flex-start;
    padding: 1px 1px 9px 1px;
    font-size: 16px;
  } */
  /*.item-row > div {
    flex: 1;
    min-width: 50%; 
    text-align: initial;
  }*/
  .item-row {
    display: flex;
    flex-direction: column; /* Esto hace que los elementos se apilen verticalmente */
    height: 100%; /* Esto establece la altura del contenedor al 100% de su contenedor padre */
  }
  
  .item-row .section-top {
    flex: 3; /* Esto asigna 3 partes de la altura disponible a la sección superior */
  }
  
  /* .item-row .section-bottom {
    
  }
  
  .tcamera{
  
  } */
  .button-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }
  
  .button {
    padding: 12px 25px;
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-left: 5px;
  }
  
  .button:hover {
    background-color: #34495e;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }
  
  

  .tcamera button{
    color: #deeefc;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 2.5rem; /* Haz el botón de eliminar un poco más grande para que sea fácil de hacer clic */

  }

  @media (max-width: 770px) {
    .camera-container {
      width: 390px;
      height: 271px;
    }
    .tcamera{
      height: 271px;
      width: 18%;
    }
    .tcamera button{
      font-size: 2rem; /* Haz el botón de eliminar un poco más grande para que sea fácil de hacer clic */ 
    }
  }
  @media (max-width: 620px) {
    .camera-container {
      width: 345px;
      height: 256px;
    }
    .tcamera{
      height: 256px;
      width: 12%;
    }
  }