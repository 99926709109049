
.outer-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Ajusta la alineación de los elementos a 'flex-start' */
    min-height: 93vh; /* Cambia 'height' a 'min-height' */
    padding: 20px; /* Agrega un poco de padding alrededor del contenedor externo */
    background-color: #f5f5f5;
    height: 93vh;
    font-family: 'Gotham-Book';
  }
  
  .container {
    display: flex;
    border:0.3px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    width: 95%;
    height: 95%;
    max-width: 1000px;
    border-radius: 10px;
  }
  
  .left-section {
    background-image: url('/public/facer.jpg');
    background-size: cover;
    width: 50%;
  }
  
  .right-section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Ajusta la alineación de los elementos a 'flex-start' */
    padding: 20px; /* Agrega un poco de padding alrededor de la sección derecha */
    background-color: #fff;
  }

 .login-form h3 {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(29, 88, 129);
  }
  .login-form h2 {
    text-align: center;
    color: rgb(29, 88, 129);
  }
  /*.login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form input {
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .login-form button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
  }*/
  
  /* Media query para pantallas pequeñas (menos de 768px) */
  @media (max-width: 768px) {
    .container {
        flex-direction: column;
        height: 93vh;
        overflow-x: hidden;
        overflow-y: hidden;
      }
  
    .left-section {
      width: 100%;
      height: 30%;
    }
    .right-section {
        width: 100%;
        height: 70%;
      }
    .login-form {
      width: 80%; /* Ajusta el ancho del formulario para pantallas más pequeñas */
      font-size: 0.8rem; /* Ajusta el tamaño de fuente para pantallas más pequeñas */
    }
  }
  
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    max-height: calc(100vh - 120px);
    overflow: hidden;
    height: 420px;
  }
  
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 80%;
    max-width: 500px;
  }
  
  
  .login-form h1 {
    margin-bottom: 30px;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Agregando el borde inferior */
    margin-bottom: 10px; /* Espacio entre los input-group */
    width: 300px;
  }
  
  .login-form input[type="email"],
  .login-form input[type="password"] {
    width: 100%;
    max-width: 300px; /* o el valor que prefieras */
    padding: 10px 10px 10px 10px; /* incrementa el padding izquierdo para dar espacio al icono */
    margin-bottom: 20px;
    border: 0.3px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    border-left: 5px solid rgb(1, 129, 248);
    outline: none;
    font-family:'Gotham-Book',FontAwesome;
    font-size: medium;
    width: 300px;
  }
  
  .login-form input[type="email"].invalid {
    border-color: red;
  }
  
  .login-form input[type="submit"] {
    padding: 10px 20px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    width: 300px;
  }
  
  .login-form input[type="submit"]:hover {
    background-color: #004cfc;
  }

  .item-row {
    display: flex;
    align-items: center;
    padding: 1px 1px 9px 1px;
    font-size: 16px;
  }