/* MyDropzone.css */

.dropzone-container {
    border: 2px dashed #0087F7;
    border-radius: 5px;
    padding: 30px;
    text-align: center;
    transition: border .3s ease-in-out;
    background: #ffffff; /* Fondo blanco para un look minimalista */
    width: 310px;
    height: 140px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .dropzone-container p {
    color: #1a202c;
    margin-bottom: 15px;
  }
  
  .dropzone-container-drag-active {
    border-color: #34D399;
  }
  
  .dropzone-container-drag-active p {
    color: #34D399;
  }
  
  .icon {
    font-size: 3rem;
    color: #1a202c;
  }
  
  .icon-drag-active {
    color: #34D399;
  }
  
  .title {
    font-size: 1.2rem; /* Tamaño de fuente aún más grande */
    font-weight: 900; /* Negrita extremadamente fuerte */
    margin-bottom: 30px;
    text-align: center;
    color: #1a202c;
    letter-spacing: 1px;
  }
  /* MyDropzone.css */

/* ... el resto de tu CSS ... */

ul {
    list-style: none;
    padding: 0;
    width: 340px; /* Asegúrate de que la lista tenga el mismo ancho que el dropzone para mantener la consistencia */
    margin: 20px auto; /* Centra la lista y añade un poco de margen en la parte superior e inferior */
  }
  
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e9f1f7;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Añade una sombra para darle profundidad */
  }
  
  ul button {
    background: none;
    border: none;
    color: #f57e75; /* Un color rojo más suave para el botón de eliminar */
    cursor: pointer;
    font-size: 1.5rem; /* Haz el botón de eliminar un poco más grande para que sea fácil de hacer clic */
  }