.App {
  display: contents;
  position: relative;
  text-align: center;
  font-family:  'Gotham-Book';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.outer-containerapp {
  display: flex;
  justify-content: start;
  align-items: center; /* Ajusta la alineación de los elementos a 'flex-start' */
  min-height: 93vh; /* Cambia 'height' a 'min-height' */
  padding: 20px; /* Agrega un poco de padding alrededor del contenedor externo */
  background-color: #f5f5f5;
  overflow: hidden; /* Desactiva el desplazamiento */
  flex-direction: column;
}

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 5px;
  width: 95%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border:0.3px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 2px;
  height: 13%;
  flex-direction: column;
}
.footer .icon{
  font-size: 2rem;
  color: #1a202c;
  cursor: pointer;
  border-radius: 5px;
}
.footer p{
  font-size:medium;
  margin-top: 2px;
}
.containerapp {
  display: flex;
  border: 0.3px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  width: 95%;
  height: 60%;
  max-width: 95%;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 5px;
  background-color: #fff;
  overflow-x: hidden;
}

.containerapp::-webkit-scrollbar {
  width: 10px; /* Ancho del scrollbar */
}

.containerapp::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo del espacio en que se mueve el scrollbar */
  border-radius: 10px; /* Redondear los bordes del espacio del scrollbar */
}

.containerapp::-webkit-scrollbar-thumb {
  background: #888; /* Color del scrollbar */
  border-radius: 10px; /* Redondear los bordes del scrollbar */
}

.containerapp::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del scrollbar al hacer hover */
}

.left-sectionapp {
  width: 40%; /* En dispositivos móviles, queremos que la sección ocupe todo el ancho */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Ajusta la alineación de los elementos a 'flex-start' */
  padding: 20px; /* Agrega un poco de padding alrededor de la sección derecha */
  background-color: #fff;
}

/* Esto aplica solo a las pantallas con una anchura de al menos 768px (típicamente tablets en modo retrato y más grandes) */
@media (max-width: 768px) {
  .left-sectionapp {
    width: 40%;
    height: 100%;
  }
  .right-sectionapp {
      width: 60%;
      height: 100%;
    }
}


.right-sectionapp {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Ajusta la alineación de los elementos a 'flex-start' */
  padding: 20px; /* Agrega un poco de padding alrededor de la sección derecha */
  background-color: #fff;

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Media query para pantallas pequeñas (menos de 768px) */
@media (max-width: 1015px) {
  /*.containerapp {
      flex-direction: column;
      height: 93vh;
      overflow-x: hidden;
    }*/

  .left-sectionapp {
    width: 40%;
    height: 100%;
  }
  .right-sectionapp {
      width: 60%;
      height: 100%;
    }
}

/* Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(40, 121, 177, 0.2);
  border-radius: 50%;
  border-top-color: rgb(40, 121, 177);
  border-bottom-color: rgb(184, 54, 116);
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}